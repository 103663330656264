@import '../node_modules/normalize.css/normalize.css';

$myriad-set-pro: "Myriad Set Pro", "Lucida Grande", "Helvetica Neue", "Helvetica", "Arial", "Verdana", "sans-serif";

html {
  height: 100%;
}

body {
  font-family: $myriad-set-pro;
  height: 100%;
}

* {
  box-sizing: border-box;
}
